<template>
	<div class="popup" style="width: 100%">
		<table class="padding-table" style="width: 95%; border-collapse: collapse;">
			<tbody>
				<tr>
					<td>No</td>
					<td>Deposit Slip Number</td>
					<td>Ref Code</td>
					<td>Method</td>
					<td>Note</td>
					<td>Status</td>
					<td>Time</td>
				</tr>
				<template v-for="(tr, idx) in this.table.data">
					<tr :key="idx">
						<td>{{ tr.number }}</td>
						<td>{{ JSON.parse(tr.json_data)[9] }}</td>
						<td>{{ tr.reference_code }}</td>
						<td>{{ JSON.parse(tr.json_data)[11] }}</td>
						<td>{{ tr.note }}</td>
						<td>{{ tr.status }}</td>
						<td>{{ dateFormatGMT7(tr.created_at) }}</td>
					</tr>
				</template>
			</tbody>
		</table>
	</div>
</template>

<script>
import moment from 'moment'
export default {
	components: {
		// 
	},
	mounted() {
		this.selected = this.$route.params.id
		this.getDataDetail()
	},
	data() {
		return {
      		selected: 0,
			table: {
				data: [],
				length: 10000,
				page: 1,
				search: "",
				order: "id",
				sort: "asc",
				total: 0,
				totalPage: 0,
				totalSearch: 0,
				limits: ["All"],
				start: 1,
				end: 0,
			},
		};
	},
	methods: {
		dateFormatGMT7(date) {
            if (date) {
				// ini karena datanya berformat `date time`, bukan `datetimezone`
				// jadi GMT(+7) ditambah +7 =>utc offset = 14
                return moment(String(date)).format('DD/MM/YYYY HH:mm')
            }
        },
		getDataDetail() {
			this.$vs.loading();
			let params = {
				log_activitas_id: this.selected,
				length: this.table.length,
				page: this.table.page,
				type: "sales-order-to-invoice",
			}
			this.$http
				.get("/api/v1/import/history-line", {
					params: params,
				})
				.then((resp) => {
				if (resp.status == "success") {
					this.table.total = resp.data.total_record;
					this.table.totalPage = resp.data.total_page;
					this.table.totalSearch = resp.data.total_record_search;
					this.table.length = resp.data.total_record_per_page;
					this.table.data = resp.data.records;
					this.setStartEnd();
					this.$nextTick(() => {
						window.print();
					});
					this.$vs.loading.close();
				} else {
					console.log(resp.message);
				}
			});
		},
		setStartEnd() {
			let valStart =
				this.table.length * this.table.page - this.table.length + 1;
			if (valStart > this.table.total) {
				valStart = 1;
			}
			if (this.table.total == 0) {
				valStart = 0;
			}
			let valEnd = this.table.length * this.table.page;
			if (valEnd > this.table.total) {
				valEnd = this.table.total;
			}
			if (this.table.totalSearch < this.table.total) {
				valEnd = this.table.totalSearch;
			}

			this.table.start = valStart;
			this.table.end = valEnd;
		},
	},
	computed: {
		watchedProperties() {
          return this.selected;
      },
	},
};
</script>

<style lang="scss">
body {
  margin: 10px auto;
  background-color: white;
  padding: 4%;
  font-size: 10pt;
  font-family: Times New Roman;
}
table, td, th {  
  border: 1px solid black;
  text-align: left;
}
table.padding-table tr td {
  padding: 1px;
}
div {
  margin-left: 1%;
  margin-right: 1.5%;
}

.d-flex {
  display: flex;
}

.color {
  background-color: #d0cdf2;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

* {
  color-adjust: exact;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
}

@page {
  size: A4;
  margin: 0;
}

.popup {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 20;
  left: 0;
  // display: none;
}

.popup:nth-of-type(2) {
  page-break-before: always;
  top: 100%;
}

@media print {
  // body * {
    // visibility: hidden;
  // }
  .printable * {
    visibility: visible;
  }
  .popup {
    display: block;
  }
}

@page {
  size: auto;
}
</style>
